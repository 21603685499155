function addZero(number) {
  return number < 10 ? '0' + number : number;
}
function addZero2(number) {
  return number < 100 ? '0' + number : number;
}
export function timeStamp2String(time) {
  let datetime = new Date();
  time = time.replace('年', '-');
  time = time.replace('月', '-');
  time = time.replace('日', ' ');
  datetime.setTime(new Date(time).valueOf());
  let year = datetime.getFullYear();
  let month = datetime.getMonth() + 1;
  let date = datetime.getDate();
  let hour = datetime.getHours();
  let minute = datetime.getMinutes();
  let second = datetime.getSeconds();
  let mseconds = datetime.getMilliseconds();
  return (
    year +
    '-' +
    addZero(month) +
    '-' +
    addZero(date) +
    'T' +
    addZero(hour) +
    ':' +
    addZero(minute) +
    ':' +
    addZero(second) +
    '.' +
    addZero2(mseconds) +
    'Z'
  );
}
